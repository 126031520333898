body {
  color: white;
  margin: 0;
  background: url("../assets/bg.jpg") no-repeat center center fixed;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

hanko-auth::part(form-item) {
  min-width: 100%; /* input fields and buttons are on top of each other  */
}
